<template>
  <div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
      <div slot="full_name" slot-scope="props" class="table-default__left">
        {{ props.row.full_name }}
      </div>
      <div slot="file_url" slot-scope="props">
        <button v-if="props.row.file_url" class="table-default__button" @click="onDownloadImg(props.row.file_url, props.row.full_name)">Скачать</button>
        <span v-else>-</span>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'

export default {
  name: 'JournalMovingList',
  components: { dropdown },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'region_name', 'number', 'full_name', 'file_url', 'created_at', 'status_name'],
        options: {
          headings: {
            id: 'ID',
            region_name: 'Регион',
            number: 'Номер билета',
            full_name: 'ФИО',
            file_url: 'Заявление',
            created_at: 'Дата',
            status_name: 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    }
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchItems(1, this.limitSelected.name)
  },
  methods: {
    fetchItems(page = 1, limit) {
      this.$store.dispatch('journal/GET_MOVING_LIST_INNER', { page, limit })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    async onDownloadImg(url, name) {
      let format = url.slice(-3)
      const response = await fetch(url)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.style = 'display: none'
      link.href = URL.createObjectURL(blob)
      link.download = `${name}.${format}`
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
};
</script>
